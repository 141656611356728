@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --gap-quarter: 0.25rem;
    --gap-half: 0.5rem;
    --gap: 1rem;
    --gap-double: 2rem;

    --width-tablet: 768px;

    --bg: #fff;
    --fg: #000;
    --accents-1: #111;
    --accents-2: #333;
    --accents-3: #888;
    --geist-foreground: #000;

    --geist-warning-light: #f7b955;
    --geist-warning: #f5a623;
    --geist-warning-dark: #f49b0b;
    --geist-success-light: #3291ff;
    --geist-success: #0070f3;
    --geist-success-dark: #0366d6;
    --geist-cyan: #79ffe1;

    --radius: 8px;

    --font-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    --font-mono: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
      Bitstream Vera Sans Mono, Courier New, monospace;
  }

  * {
    box-sizing: border-box;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-size: 16px;
  }

  body {
    min-height: 100vh;
    background: var(--bg);
    color: var(--fg);
    font-family: var(--font-sans);
    line-height: 1.5;

    background: linear-gradient(180deg,hsla(0,0%,100%,0) 0,#fff 300px),fixed 0 0 /20px 20px radial-gradient(#d1d1d1 1px,transparent 0),fixed 10px 10px /20px 20px radial-gradient(#d1d1d1 1px,transparent 0);

    /* Hack */
    overflow-x: hidden;
  }

}
